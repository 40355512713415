import React from 'react'
import {
  Container,
  Section,
  LinkButton,
  Seo,
  CommonBanner
} from '@components'
import { OfferForm } from '../../components/site/offer'
import { PageLayout } from '../../components/site/layout/page-layout'

const ConsultPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Talk to a Mobile Security Expert"/>

      <Section>
        <Container className="py-8">
          <div className="flex justify-center">
            <LinkButton
              className="mt-8 border-white button-secondary"
              href="/signup"
            >
              Try Approov for Free
            </LinkButton>
          </div>
          <p>
            A live discussion is the quickest way to find out how the Approov
            Mobile App and API protection solution can shield your apps and APIs
            from attack.
          </p>
          <p>
            An Approov security expert will ask you some questions about your
            use case and the mobile and API technologies you use. With this information, we
            will explain how Approov can be deployed to deliver immediate and
            ongoing security and business benefits to your organization.
          </p>
        </Container>
      </Section>

      <Section className="py-8 bg-gray-100">
        <Container>
          <h2 className="mt-0 mb-4 text-center">
            Complete this form and we will set up a call with you
          </h2>
          <OfferForm offer="consult" />
        </Container>
      </Section>
    </PageLayout>
  )
}

export default ConsultPage
